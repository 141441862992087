<template>
    <div class="body">
        <div class="add">
            <div class="add-loterry">
                <select @change="chooseLottery" v-model="selectedLottery">
                    <option v-for="(lotter, i) in lottery" :value="i" :key="i">{{lotter.NAME}}</option>
                </select>
                <span @click="addToBitrix">Обновить</span>
                <span v-if="update">Обнолвено</span>
            </div>
            <div class="add-data">
                <div class="add-data-left">
                    <div> Название</div>
                    <textarea v-model="names"></textarea>
                </div>
                <div class="add-data-middle">
                    <div>Доп. поле 1</div>
                    <textarea v-model="addField1"></textarea>
                </div>
                <div class="add-data-right">
                    <div> Доп поле 2</div>
                    <textarea v-model="addField2"></textarea>
                </div>
            </div>
            <div v-if="selectedLottery !== null && winner" class="add-data-winner">
                Победители
                <div v-for="(winner, index) in Object.values(this.lottery[this.selectedLottery].PROPERTY_103)" v-bind:key="index" class="add-data-winner-pers">
                    <span>{{winner}}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                bxApi: 'https://vita-max.bitrix24.ru/rest/229/dz3nqgi2bjgxkve3',
                lottery: [],
                selectedLottery: null,
                names:[],
                addField1: [],
                addField2: [],
                update: false,
                winner: false
            }
        },
        methods: {
            save(){

            },
            chooseLottery(){
                this.names = (this.lottery[this.selectedLottery].PROPERTY_101)?Object.values(this.lottery[this.selectedLottery].PROPERTY_101).join('\n'): [];
                this.addField1 = (this.lottery[this.selectedLottery].PROPERTY_107)?Object.values(this.lottery[this.selectedLottery].PROPERTY_107).join('\n'):[];
                this.addField2 = (this.lottery[this.selectedLottery].PROPERTY_109)?Object.values(this.lottery[this.selectedLottery].PROPERTY_109).join('\n'): [];
            },
            addToBitrix(){
                let params = {
                    'IBLOCK_TYPE_ID': 'lists',
                    'IBLOCK_ID': '35',
                    'ELEMENT_ID': this.lottery[this.selectedLottery].ID,
                    'FIELDS': {
                        'NAME': this.lottery[this.selectedLottery].NAME,
                        'PROPERTY_103': (this.lottery[this.selectedLottery].PROPERTY_103)? Object.values(this.lottery[this.selectedLottery].PROPERTY_103) : [],
                        'PROPERTY_101': (Array.isArray(this.names))?  this.names: this.names.split('\n'),
                        'PROPERTY_105': this.lottery[this.selectedLottery].PROPERTY_105,
                        'PROPERTY_107': (Array.isArray(this.addField1))? this.addField1: this.addField1.split('\n'),
                        'PROPERTY_109': (Array.isArray(this.addField2))? this.addField2 : this.addField2.split('\n'),
                    }
                };
                this.getInfo("lists.element.update", params);
                this.getFromBitrix();
                this.update = true;
                setTimeout(() => {
                    this.update = false;
                }, 2000);
            },
            getInfo(Method = '', data = {}) {
                const url = this.bxApi + "/" + Method;
                let result = null;
                try {
                    const response = fetch(url, {
                        method: 'POST', // или 'PUT'
                        body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    // const json =  response.json();
                    result = response.then(function (a) {
                        // window.console.log(a);
                        return a.json(); // call the json method on the response to get JSON
                    });
                }
                catch (e) {
                    window.console.log(e)
                }
                return result;

            },
            getFromBitrix(){
                let self = this;
                this.getInfo("lists.element.get", {
                    'IBLOCK_TYPE_ID': 'lists',
                    'IBLOCK_ID': '35',
                    'FILTER': {
                        'PROPERTY_105': 93
                    }
                }).then(function (data) {
                    for (let i = 0; i < data.total; i++) {
                        self.lottery.push(data.result[i]);
                    }
                });
                if (this.lottery[this.selectedLottery]) {
                    if (this.lottery[this.selectedLottery].PROPERTY_103) {
                        this.winner = true;
                    }
                }
            }
        },
        created() {
        },
        mounted() {
            this.getFromBitrix();
        }
    }
</script>