<template>
    <div class="body">
<!--        <div class="pass" v-if="!show">-->
<!--            <div>Введите супер секретный пароль</div>-->
<!--            <input type="password" v-model="pass">-->
<!--            <button @click="checkPass">Погнали</button>-->
<!--        </div>-->
        <div>
            <div class="content">
                <div class="content-field">
                    <div class="content-field-gamer">
                        <div class="content-field-gamer-top">
                            <div class="content-field-addGamer" >
                                <span>Добавление игрока</span>
                                <input type="text" v-model="gamerName" placeholder="Имя игрока">
                                <input type="text" v-model="gamerBacks" placeholder="Баланс игрока">
                                <button @click="addGamer">Добавить игрока</button>
                            </div>
                            <div class="content-field-addbacks">
                                <span>Начисление витабаксов</span>
                                <select v-model="gamerName">
                                    <option disabled value="">Выберите игрока</option>
                                    <option v-for="(gamer, i) in gamers" :value="i" v-bind:key="i+'gamer'">
                                        {{gamer.name}}
                                    </option>
                                </select>
                                <input type="text" v-model="gamerBacks" placeholder="Введите сумму">
                                <button @click="addBacks">Начислить</button>
                            </div>
                        </div>
                        <div class="content-field-gamer-bottom">
                            <div class="content-field-timer">
                                <div class="content-field-timer-head">Таймер</div>
                                <button @click="stopTimer">Стоп</button>
                                <vac
                                        ref="timer"
                                        :end-time="timer"
                                        :autoStart="false"
                                >
                                    <template
                                            v-slot:process="{ timeObj }">
                                        <span>{{ `Осталось времени: ${timeObj.m}:${timeObj.s}` }}</span>
                                    </template>
                                    <template
                                            v-slot:finish>
                                        <span>Время вышло</span>
                                    </template>
                                </vac>
                            </div>
                            <div class="content-field-startTimer">
                                <button @click="startTimer(30)">Старт 30 сек</button>
                                <button @click="startTimer(60)">Старт 1 мин</button>
                                <button @click="startTimer(120)">Старт 2 мин</button>
                            </div>

                        </div>
                        <div class="content-gamers">
                            <div class="content-gamers-top">
                                <div :class="'content-gamers-gamer' + (i==active? ' active': '')"
                                     v-for="(gamer, i) in gamers" v-bind:key="i+'gamer'">
                                    <div class="content-gamers-gamer-name">
                                        {{gamer.name}}
                                    </div>
                                    <div class="content-gamers-gamer-count">
                                        {{gamer.backs}}
                                    </div>
                                    <div class="content-gamers-gamer-give">
                                        <button :data-gamer="i" @click="vote">Голосовать</button>
                                    </div>
                                </div>
                            </div>
                            <div class="content-gamers-bottom">
                                <div class="content-field-next">
                                    <button @click="nextActive">Следующий ход</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="content-field-field">
                        <div class="content-field-randomCard">
                            <div class="content-field-randomCard-card">
                                <img :src="cardCurrent === null ? '/assets/images/game/bonuses/back.jpg' : '/assets/images/game/cards/card ('+cardCurrent+').jpg'"/>
                            </div>
                            <div class="content-field-randomCard-button">
                                <button @click="getRandomCard">Тянуть карточку</button>
                            </div>
                            <div class="content-field-randomCard-button for-help">
                                <button @click="showSmallInstruct">Подсказка</button>
                            </div>
                            <div class="content-field-randomCard-button for-help">
                                <button @click="showInstruct">Полные правила</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <audio ref="game" src="/assets/music/music.mp3" preload="auto"></audio>
            <audio ref="win" src="/assets/music/win.mp3" preload="auto"></audio>
        </div>
        <div @click="newGame" class="refresh">
            Новая игра
        </div>
        <div class="help" v-if="showSmallHelp">
            <b>Командный бонус</b>	Игрок получает по одному ВИТА баксу от всех игроков за столом<br>
            <b>Спонсорский бонус</b>	Игрок получает по одному ВИТА баксу от двух соседних игроков за столом<br>
            <b>Экспресс бонус</b>	Игрок получает по одному ВИТА баксу от модератора<br>
            <b>Бонус путешествие</b>	3 ВИТА бакса при подсчёте к общему количеству денег<br>
            <b>Авто Бонус</b>	5 ВИТА баксов при подсчёте к общему количеству денег<br>
            <b>Бонус недвижимость</b>	10 ВИТА баксов при подсчёте к общем количеству денег<br>
            <b>Антибонус:</b> Квалификация не выполнена	Пропуск хода<br>
        </div>
        <div class="help" v-if="showHelp">
            <h1>Инструкция к игре "Кто хочет стать Акционером?"</h1>

            <p>Данная бизнес-игра предназначена для Дистрибьюторов Компании ВИТАМАКС, применяя данный формат игры в своей структуре,<br>
             Лидер сможет помочь новым Представителям (или тем, кто стал развиваться более медленными темпами, чем планировалось)<br>
             закрепить основные знания о продукции, маркетинг- плане, раскрыть понятия лидерства и команды, узнать мотивацию<br>
             участников игры, выявить их возможности и способности, чтобы дать правильное направление в дальнейшем развитии<br>
             и обучении. Совместное участие в игре – еще один шаг к созданию команды!</p>

            <p>Набор игры состоит из:</p>

            <p>•	175 карточек с вопросами по пяти важным разделам бизнеса с ВИТАМАКС:<br>
            - Продукция – карточки желтого цвета;<br>
            - Бизнес – карточки голубого цвета;<br>
            - Дополнительный доход – карточки салатного цвета;<br>
            - Самопрезентация - карточки фиолетового цвета;<br>
            - Построение команды - карточки оранжевого цвета;</p>
            <p>•	6 видов бонусных карточек + 1 антибонус:</p>

            <b>Командный бонус</b>	Игрок получает по одному ВИТА баксу от всех игроков за столом<br>
            <b>Спонсорский бонус</b>	Игрок получает по одному ВИТА баксу от двух соседних игроков за столом<br>
            <b>Экспресс бонус</b>	Игрок получает по одному ВИТА баксу от модератора<br>
            <b>Бонус путешествие</b>	3 ВИТА бакса при подсчёте к общему количеству денег<br>
            <b>Авто Бонус</b>	5 ВИТА баксов при подсчёте к общему количеству денег<br>
            <b>Бонус недвижимость</b>	10 ВИТА баксов при подсчёте к общем количеству денег<br>
            <b>Антибонус:</b> Квалификация не выполнена	Пропуск хода<br>
            <p>•	200 ВИТАбаксов номиналом один ВИТА бакс из расчёта 30 купюр на человека и 6-и игроков в команде;<br>
            •	игровых костей (набор из 2-х шт.)  для определения первого играющего;<br>
            •	песочных часов для таймирования заданий и обратной связи;<br>
            •	инструкции для игроков;<br>
            </p>
            <p>* Рекомендуемое количество игроков от 4-х до 6 человек.</p>

            <p>Длительность игры – 2 часа, за которые игроки успевают сыграть не менее 60 карточек с заданиями.</p>
            <h1>Ход игры:</h1>

            <p>1.	До начала игры необходимо выбрать модератора, который определяет темы, следит за временем <br>
                выполнения заданий и обратной связи. Он контролирует соблюдение всех правил игры, выдачу бонусов<br>
                и подсчёт баллов. При этом он обладает достаточным уровнем знаний, чтобы помочь игрокам с ответами<br>
                на сложные для них вопросы, пояснить любой вопрос. Например, модератором может быть опытный <br>
                Лидер первой линии.</p>

            <p>2. Для определения, кто из игроков будет делать первый ход, участники по очереди выбрасывают игральные<br>
                кости. Тот, кто выбросил вариант с наибольшим числом, будет ходить первым, далее очерёдность<br>
                определяется от него по часовой стрелке.</p>

            <p>3. Время выполнения каждого задания указано в вопросе:<br>
            ответить на вопрос - 1 минута<br>
            сделать презентацию - 2 минуты<br>
                дать обратную связь – 30 секунд.</p>

            <p>*Модератор чётко следит за таймером и останавливает игрока ровно через минуту/ две минуты в зависимости<br>
                от типа задания.</p>

            <p>4.Получивший право первого хода, игрок внимательно вслух читает задание. У него есть право задать модератору<br>
                два уточняющих вопроса, если что-то в задании было непонятно. После чего запускается таймер и игрок начинает<br>
                выполнение задания.</p>

            <p>5. Если участник затрудняется ответить на доставшийся ему вопрос, то за один ВИТАбакс он может купить<br>
                у модератора право на подсказку (это может быть помощь игроков по команде или использование информации<br>
                из брошюр ВИТАМАКС, которые лежат на игровом столе). На это у него также будет только одна минута!</p>

            <p>6.Все участники команды внимательно слушают ответ, чтобы дать обратную связь в течение 30 секунд по пунктам:<br>
            •	Что удалось?<br>
            •	Над чем стоит поработать?<br>
                •	Как бы я сделал сам?</p>

            <p>*Очерёдность по обратной связи устанавливается модератором с учётом пожелания игроков.</p>

            <p>7. Участник, давший обратную связь, оценивает ответ или презентацию и решает о передаче игроку вознаграждения<br>
                - 1 ВИТАбакс. Оценка происходит по критериям:
            •	ответил ли участник на вопрос;
                •	был ли он убедителен.</p>

            <p>После получения обратной связи от всех игроков ход переходит к следующему участнику игры(см. пункт № 1).</p>
            <p>*За оценкой выступления также следит модератор.</p>

            <p>8. Если участник в момент игры достаёт Бонусные карточки, то без выполнения какого-либо задания он получает<br>
                соответствующее число ВАТА баксов (см. пункт: набор игры состоит).</p>

            <p>9. Если на заработанные в ходе игры ВИТАбаксы вы захотите приобрести обязательные для победителя составляющие<br>
                успеха, а именно:<br>
            ПУТЕШЕСТВИЕ –это стоит 3 ВИТАбакса<br>
            МАШИНУ – это стоит 5 ВИТАбаксов<br>
            КВАРТИРУ – это стоит 10 ВИТАбаксов;<br>
            Сделать данные покупки можно только в конце игры, после того как<br>
                будет разыграно всеми участниками не менее 60 карточек.</p>
            <p>10. Если у участника игры для голосования за выполнение задания<br>
            другими не осталось ВИТА баксов, то он становится наблюдателем и<br>
            более не участвует в игре.<br>
                Отвечайте на вопросы карточек, выполняйте задания и зарабатывайте ВИТА баксы!</p>

            <h1>Определение победителей:</h1>
            <p>Победителем становится игрок, заработавший больше всех ВИТА-баксов и при этом ставший обладателем ПУТЕШЕСТВИЯ,<br>
                МАШИНЫ и КВАРТИРЫ  (их можно в игре получить как Бонус или купить на заработанные ВИТАбаксы – см. пункт № 9),<br>
                т.е. после приобретения всех трёх составляющих успешного бизнесмена у победителя должно остаться самое большое<br>
                количество ВИТА-баксов по сравнению с другими игроками. При подсчёте баллов в конце игры бонусные карточки<br>
                учитываются в соответствии с их стоимостью, см. пункт « В набор игры входят».</p>
            <p>Победителю присваивается Ранг АКЦИОНЕР, а также он награждается дипломом за 1-е место в игре «Кто хочет стать<br>
                Акционером?»!</p>

            <p>* Если за столом более 6 участников, то рекомендуем определить игроков, занявших 2-е и 3-е место и вручить им дипломы.</p>

            <p>* Длительность игры: 2 часа, за которые все участники, сидящие за одним столом, должны сыграть не менее 60 карт,<br>
                после чего останавливается игра и идёт подсчёт ВИТАбаксов для определения победителя! В случае равной суммы денег<br>
                у игроков, делается ещё один круг с ответами на задания в карточках.</p>

            <h1>Итак, можно начинать игру! Желаем удачи КАЖДОМУ!!!</h1>

        </div>
        <div class="shadow" @click="closeHelp" v-if="showHelp || showSmallHelp">

        </div>
    </div>
</template>

<script>

    export default {
        data() {
            return {
                showSmallHelp: false,
                showHelp: false,
                timer: null,
                gamerName: null,
                gamerBacks: null,
                active: (this.$cookies.get("active"))? this.$cookies.get("active"):0,
                gamers: (this.$cookies.get("gamers"))? JSON.parse(this.$cookies.get("gamers")):[],
                bxApi: 'https://vita-max.bitrix24.ru/rest/229/dz3nqgi2bjgxkve3',
                usedCards: (this.$cookies.get("usedCards"))? JSON.parse(this.$cookies.get("usedCards")):[],
                cardCurrent: null
            }
        },
        methods: {
            closeHelp(){
                this.showHelp = false;
                this.showSmallHelp = false;
            },
            showInstruct(){
                this.showHelp = (this.showHelp)? false :true
            },
            showSmallInstruct(){
                this.showSmallHelp = (this.showSmallHelp)? false :true
            },
            newGame(){
               this.active = 0;
               this.gamers = [];
               this.usedCards = [];
               this.cardCurrent = null;
                this.setCookie();
            },
            setCookie(){
                this.$cookies.set("gamers", JSON.stringify(this.gamers), 36000000000);
                this.$cookies.set("usedCards", JSON.stringify(this.usedCards), 36000000000);
                this.$cookies.set("active", this.active, 36000000000);
            },
            vote(e){
                let voter = e.srcElement.dataset.gamer;
                this.gamers[voter].backs--;
                this.gamers[this.active].backs++;
            },
            getRandomCard(){
                let rnd = this.getRandomInt(1, 206);
                if (this.usedCards.indexOf(rnd) != -1){
                    rnd = this.getRandomInt(1, 206);
                }
                this.usedCards.push(rnd);
                this.cardCurrent = rnd;
                this.setCookie();
            },
            stopTimer(){
                this.$refs.timer.finishCountdown();
            },
            startTimer(sec){
                this.timer = new Date().getTime() + sec*1000;
                setTimeout(() => {
                    this.$refs.timer.startCountdown(true);
                }, 100);
            },
            getRandomInt(min, max) {
                return Math.floor(Math.random() * (max - min)) + min;
            },
            nextActive(){
                this.active = (this.active >= this.gamers.length-1)? 0 : this.active+1;
                window.console.log(this.active);
                this.cardCurrent = null;
                this.setCookie();
            },
            addBacks(){
                window.console.log(this.gamerName);
                this.gamers[this.gamerName].backs = Number(this.gamers[this.gamerName].backs) + Number(this.gamerBacks);
                this.gamerName = null;
                this.gamerBacks = null;
                this.setCookie();
            },
            addGamer() {
                if (this.gamers[5] == undefined) {
                    this.gamers.push(
                        {
                            'name': this.gamerName,
                            'backs': this.gamerBacks
                        }
                    );
                }
                    this.gamerName = null;
                    this.gamerBacks = null;
                    this.setCookie();

            },
            getInfo(Method = '', data = {}) {
                const url = this.bxApi + "/" + Method;
                let result = null;
                try {
                    const response = fetch(url, {
                        method: 'POST', // или 'PUT'
                        body: JSON.stringify(data), // данные могут быть 'строкой' или {объектом}!
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    });
                    // const json =  response.json();
                    result = response.then(function (a) {
                        // window.console.log(a);
                        return a.json(); // call the json method on the response to get JSON
                    });
                }
                catch (e) {
                    window.console.log(e)
                }
                return result;
            }
        },
        created() {
        },
        mounted() {


        }
    }
</script>