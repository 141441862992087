import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueCookies from 'vue-cookies'
import VeeValidate from 'vee-validate'

import vueAwesomeCountdown from 'vue-awesome-countdown'


Vue.use(vueAwesomeCountdown, 'vac')


Vue.use(VeeValidate);
Vue.config.productionTip = false
Vue.use(VueCookies)



new Vue({
  router,
  store,
  render: h => h(App),
  /*mounted() {
    document.dispatchEvent(new Event('render-event'))
  }*/
}).$mount('#app')
